@font-face {
    font-family: "Pretendard-Bold";
    src: url("../src/fonts/Pretendard-Bold.woff") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard-ExtraLight";
    src: url("../src/fonts/Pretendard-ExtraLight.woff") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    src: url("../src/fonts/Pretendard-Regular.woff") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard-Light";
    src: url("../src/fonts/Pretendard-Light.woff") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard-SemiBold";
    src: url("../src/fonts/Pretendard-SemiBold.woff") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard-Black";
    src: url("../src/fonts/Pretendard-Black.woff") format("woff2");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.first-selection {
    width: 100vw;
    /* 화면 너비에 맞게 설정 */
    height: 100vh;
    /* 화면 높이에 맞게 설정 */
    flex-shrink: 0;
    background-image: url("./images/MainBackground.png");
    /* 배경 이미지 설정 */
    background-size: cover;
    /* 배경 이미지를 화면에 맞게 조정 */
    background-position: center;
    /* 배경 이미지를 가운데로 정렬 */
    background-repeat: no-repeat;
    /* 배경 이미지 반복 방지 */
    display: flex;
    justify-content: center;
    /* 수평 가운데 정렬 */
}

.Mainpages {
    width: 100vw;
    /* 화면 너비에 맞게 설정 */
    height: 100vh;
    /* 화면 높이에 맞게 설정 */
}

.container {
    width: 18.9375rem;
    height: 38.125rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.5rem;
}

.quote-box {
    width: 18.9375rem;
    height: 15.5rem;
    flex-shrink: 0;
    fill: #f8f3a5;
    flex-direction: column;
    align-items: center;
    margin-top: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* 커서를 포인터로 설정하여 호버 효과 적용 */
    transition: background-color 0.3s ease;
    /* 배경색 변경에 대한 트랜지션 효과 추가 */
    animation: fadeIn 1s ease-in-out;

}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2px;
    flex-shrink: 0;
    stroke-width: 2px;
    stroke: #f6f4a5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 3.56rem;
}


.title1 {
    flex-shrink: 0;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard-Black;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.title2 {
    flex-shrink: 0;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard-Black;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.description {
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.YoungSection {
    width: 100%;
    height: 253.4375rem;
    background: #fcf6dd;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* 추가: 내부 텍스트 정렬 */
}

.story {
    width: 19rem;
    height: 7.5rem;
    flex-shrink: 0;
    color: #000;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4.87rem;
    text-align: left;

}

.BrightDoor1 {
    flex-shrink: 0;
    border-radius: 2.5rem;
    margin-top: 2.63rem;
    width: 21.375rem;
    height: 35.625rem;
    flex-shrink: 0;

}

.BrightDoor2 {
    flex-shrink: 0;
    border-radius: 2.5rem;
    margin-top: 21rem;
    width: 21.375rem;
    height: 35.625rem;
    flex-shrink: 0;
}

.story2 {
    display: flex;
    width: 15.375rem;
    height: 4.625rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #000;

    text-align: center;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 27rem;
}

.story3 {
    display: flex;
    width: 15.375rem;
    height: 4rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 24.56rem;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.story4 {
    width: 15.375rem;
    height: 5.375rem;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.DoorButton {
    width: 21.375rem;
    height: 35.625rem;
    flex-shrink: 0;
    border: none;
    outline: none;
    background-color: transparent;
    margin-top: 2rem;
    transition: opacity 0.5s ease;
    /* 투명도 변경에 대한 트랜지션 효과 추가 */
}

.DoorButton-image {
    width: 14.4375rem;
    height: 34.875rem;
    flex-shrink: 0;
    height: 34rem;
    flex-shrink: 0;
    border: none;
    /* 테두리 제거 */
    outline: none;
    /* 외곽선 제거 */
    background-color: transparent;
}


.ElderSection {
    height: 329.125rem;
    background: #5C2380;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.story-elder1,
.story-elder2,
.story-elder3,
.story-elder4 {
    color: #fff;
    font-family: Pretendard-Light;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.3rem;
    /* 줄간격을 2배로 설정 */
    flex-shrink: 0;
}

.story-elder1 {
    width: 20.6875rem;
    height: 7.875rem;
    margin-top: 5.44rem;
}

.story-elder2 {
    width: 17rem;
    height: 8.9375rem;
    margin-top: 0rem;
}

.story-elder3 {
    width: 19.625rem;
    height: 8.6875rem;
    margin-top: 1.75rem;
}

.story-elder4 {
    width: 18rem;
    height: 8.125rem;
    flex-shrink: 0;
    margin-top: 30.81rem;
    margin-bottom: 30.81rem;
}

.Snow {
    width: 21.625rem;
    height: 36.5625rem;
    flex-shrink: 0;
    margin-bottom: 32.4rem;
}

.Hand {
    width: 22.625rem;
    height: 37.5625rem;
    flex-shrink: 0;
    margin-top: 40rem;
}

.HandButton {
    max-width: 100%;
    /* 부모 요소의 가로 크기에 맞게 확대 */
    max-height: 100%;
    /* 부모 요소의 세로 크기에 맞게 확대 */
    width: auto;
    /* 가로 크기를 자동으로 조정하여 비율 유지 */
    height: auto;
    /* 세로 크기를 자동으로 조정하여 비율 유지 */
    flex-shrink: 0;
}

.story2-elder {
    display: flex;
    width: 15.375rem;
    height: 4rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 24.56rem;
    color: #ffffff;

    text-align: center;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.story3-elder {
    width: 15.375rem;
    height: 5.375rem;
    flex-shrink: 0;
    color: #ffffff;
    text-align: center;
    font-family: Pretendard;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.FirstPage {
    display: flex;
    height: 52.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fcf6dd;
}

.Success {
    display: flex;
    height: 52.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fcf6dd;
}

.BigLogoButton {
    width: 19.75rem;
    height: 8.875rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    /* 테두리 제거 */
    outline: none;
    /* 외곽선 제거 */
    background-color: transparent;
    /* 배경색을 투명하게 설정 */
}



.BigLogoAnimation {
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.MidiumLogoButton {
    width: 18.875rem;
    height: 5.9375rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    /* 테두리 제거 */
    outline: none;
    /* 외곽선 제거 */
    background-color: transparent;
    /* 배경색을 투명하게 설정 */
}

.BigLogo {
    width: 19.75rem;
    height: 8.875rem;
    flex-shrink: 0;
}

.MidiumLogo {
    width: 18.875rem;
    height: 5.9375rem;
    flex-shrink: 0;
}

.WelomeMessage {
    display: flex;
    width: 17rem;
    height: 7rem;
    padding: 0rem 1.006rem 0.30256rem 0rem;
    align-items: center;
    flex-shrink: 0;
    color: #000;
    text-align: left;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.WelomeMessage2 {
    width: 17rem;
    height: 14rem;
    padding: 0rem 1.006rem 0.30256rem 0rem;
    align-items: center;
    flex-shrink: 0;
    color: #000;
    text-align: left;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2rem;
}

.WelomeMessage3 {
    width: 17rem;
    height: 14rem;
    padding: 0rem 1.006rem 0.30256rem 0rem;
    justify-content: center;
    flex-shrink: 0;
    color: #b4b4b4;
    text-align: left;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Explaination-Container {
    display: flex;
    /* 플렉스박스 사용 */
    justify-content: center;
    /* 가로축 중앙 정렬 */
    align-items: center;
    /* 세로축 중앙 정렬 */
}

.Explaination {
    width: 24.375rem;
    height: 50rem;
    background-size: cover;
    background-position: center center;
    /* 배경 이미지가 중앙에 위치 */
    background-repeat: no-repeat;
    /* 배경 이미지가 반복되지 않음 */
    display: flex;
    /* 플렉스박스 사용 */
    justify-content: center;
    /* 가로축 중앙 정렬 */
    align-items: center;
    /* 세로축 중앙 정렬 */
}



.Next {
    background: #FFF;
    width: 24.375rem;
    height: 7rem;
    flex-shrink: 0;
    margin-top: 43rem;
    color: #B049F0;
    align-items: center;
    /* 세로축 중앙 정렬 */
    text-align: center;
    font-family: Pretendard-bold;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: transparent;
    cursor: pointer;
    transition: background 0.3s ease;
    /* 배경색 전환을 부드럽게 */
}

.Next:active {
    background: #DDD;
    /* 클릭 시 어두운 색으로 변경 */
}

.tab-container {
    width: 100%;
    /* 화면의 전체 너비를 차지하도록 설정 */
    height: 5.07813rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    /* 화면에 고정 */
    bottom: 0;
    /* 화면의 바닥에 위치 */
    background-color: white;
    /* 배경색 추가 */
    z-index: 1000;
    /* 다른 요소 위에 위치 */
    position: relative;
    /* 추가 */
}


.tabs {
    display: flex;
    justify-content: center;
    /* 탭 버튼들을 가로로 중앙 정렬 */
    width: 24rem;
    /* 탭 버튼들이 모이는 너비 */
    align-items: center;
    /* 세로 중앙 정렬 */
}

.tab-button {
    width: 3rem;
    height: 5.07813rem;
    flex-shrink: 0;
    flex: 1;
    text-align: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    /* 버튼 사이에 간격 추가 */
    outline: none;
    /* 버튼 클릭 시 나타나는 아웃라인 제거 */
    padding: 0;
    /* 버튼의 기본 패딩 제거 */
}

.tab-button img {
    width: 1.7rem;
    height: 1.7rem;
    opacity: 0.5;
    /* 초기 투명도 50% */
    transition: opacity 0.3s;
    /* 투명도 전환 애니메이션 */
}

.tab-button:hover img {
    opacity: 1;
    /* 호버 시 투명도 100% */
}

.tab-button.active img {
    opacity: 1;
    /* 선택된 버튼의 투명도 100% */
}

.tab-content {
    display: none;
    padding: 20px;
    border: 1px solid #ccc;
    border-top: none;
}

.tab-content.active {
    display: block;
}

.Home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(177, 73, 240, 0.05);
    align-items: center;
}

.HomeComponent1 {
    width: 24.375rem;
    height: 20.25rem;
    display: flex;
    flex-direction: column;

    align-items: center;
    background-color: #FFF;
    justify-content: center;
}

.HomeComponent1-2 {
    width: 23rem;
    height: 10rem;
    flex-shrink: 0;
    display: flex;
    /* 요소들을 가로로 배치 */
    flex-direction: row;
    justify-content: space-between;
    /* 요소들 사이에 동일한 간격을 둠 */
    justify-content: space-around;
    /* 양 끝에 반만큼의 간격을 둠 */
    align-items: center;
    /* 가로 중앙 정렬 */
    padding: 5%;
}


.MyPoints {
    width: 4.5rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    /* 요소들을 세로로 배치 */
    justify-content: center;
    /* 세로 가운데 정렬 */
    align-items: center;
    /* 가로 가운데 정렬 */

}


.MyPoints1 {
    display: flex;
    width: 5.25rem;
    height: 1.3125rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 1.3125rem */
}

.MyPoints2 {
    display: flex;
    width: 5.25rem;
    height: 1.5625rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 1.875rem */
}

.MissionStatus {
    width: 9.1875rem;
    height: 9.1875rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: 90%;
}

.mission {
    color: #000;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 0.9375rem */

}

.HomeComponent2 {
    width: 24.375rem;
    height: 26.125rem;
    display: flex;
    flex-direction: column;
    /* 내부 요소들을 세로로 배치 */
    align-items: center;
    /* 가로 중앙 정렬 */
    background-color: rgba(177, 73, 240, 0.05);
    margin-top: 0.2rem;
}

.TodayHarmonyStory {
    width: 18.375rem;
    height: 2.1875rem;
    flex-shrink: 0;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.StoryComponent {
    width: 15.3125rem;
    height: 13.6875rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    /* 내부 요소들을 세로로 배치 */
    justify-content: center;
    background-repeat: no-repeat;
    /* 배경 이미지 반복 안 함 */
    background-size: cover;
    /* 배경 이미지가 요소 크기에 맞게 조정 */
    background-position: center;
    /* 배경 이미지가 중앙에 위치하도록 설정 */
}

.HarmonyStoryTitle {
    width: 11.6875rem;
    height: 10.0625rem;
    flex-shrink: 0;
    color: #5C2380;
    font-family: Pretendard-Black;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-decoration: unset;
    cursor: pointer;
    margin-left: 1.5rem;

}

.PostDate {
    width: 10.26738rem;
    height: 1.54463rem;
    flex-shrink: 0;
    color: #5C2380;
    font-family: Pretendard;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: 1.5rem;

}



.PostModal {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    transition: bottom 0.3s ease-in-out;
    flex-direction: column;
    justify-content: flex-start;
    /* 위에서부터 정렬 */
}

.PostModal.show {
    bottom: 0;
}


.PostButton {
    width: 24.375rem;
    height: 3.965rem;
    border: transparent;
    margin-top: 4rem;
    cursor: pointer;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 100%;
}

.PostContent {
    width: 24.375rem;
    height: 60.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* 위에서부터 정렬 */
    align-items: center;
    /* 수평 가운데 정렬 */
    margin: auto;
    /* 화면 가운데에 오게 함 */
}

.PostContent>*+* {
    margin-top: 3rem;
    /* 첫 번째 자식 요소를 제외한 나머지 자식 요소들에게 간격을 줌 */
}

.CloseButton {
    width: 1.3rem;
    height: 1.3rem;
    flex-shrink: 0;
    border: transparent;
    background-color: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 4rem;
    right: 10rem;
    /* 부모 요소 오른쪽에서 10px 왼쪽에 위치 */

}


/* kind.css */
.Points {
    display: flex;
    height: 50rem;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(177, 73, 240, 0.05);
    align-items: center;

}

.PointComponent1 {
    width: 24.375rem;
    height: 11rem;
    flex-shrink: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* 세로로 배치 */
    justify-content: center;
    /* 수직 중앙 정렬 */
    align-items: center;
    /* 수평 중앙 정렬 */
}

.PointComponent2 {
    width: 24.375rem;
    height: 10rem;
    flex-shrink: 0;
    background-color: rgba(177, 73, 240, 0.05);
    display: flex;
    flex-direction: column;
    /* 세로로 배치 */
    justify-content: center;
    /* 수직 중앙 정렬 */
    align-items: center;
    /* 수평 중앙 정렬 */

}

.MyPoints-2 {
    width: 9rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    /* 요소들을 세로로 배치 */
    justify-content: center;
    /* 세로 가운데 정렬 */
    align-items: center;
    /* 가로 가운데 정렬 */
}


.MyPoints1-2 {
    display: flex;
    width: 8.6875rem;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 1.3125rem */
}

.MyPoints2-2 {
    display: flex;
    width: 15rem;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 0.5rem;
    /* 1.875rem */
}

.Width100Button {
    width: 24.375rem;
    height: 4.25rem;
    flex-shrink: 0;
    background: #FFF;
    color: #000;
    text-align: center;
    font-family: Pretendard-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: transparent;
    margin-top: 0.2rem;
    transition: background 0.3s ease;
    /* 배경색 전환을 부드럽게 */
}

.Width100Button:active {
    background: #DDD;
    /* 클릭 시 어두운 색으로 변경 */
}

.PointComponent2 {
    width: 24.375rem;
    height: 25rem;
    flex-shrink: 0;
    margin-top: 0.2rem;
}

.Ongoing {
    display: flex;
    flex-direction: row;
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    margin-top: 2.2rem;
}

.OngoingContenets1 {
    display: flex;
    width: 1.3125rem;
    height: 1.625rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    /* 2rem */
}

.OngoingContenets2 {
    display: flex;
    width: 1.3125rem;
    height: 1.625rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #b4b4b4;
    text-align: center;
    font-family: Pretendard-ExtraLight;
    font-size: 3rem;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    margin-top: 0.5rem;
    /* 2rem */
}

.OngoingContenets3 {
    display: flex;
    width: 1.3125rem;
    height: 1.625rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #b4b4b4;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    margin-top: 1.2rem;
    /* 2rem */
}


.MyPage {
    height: 50rem;
    display: flex;
    flex-direction: column;
    background-color: rgba(177, 73, 240, 0.05);
    align-items: center;
}

.MyPageComponent1 {
    width: 24.375rem;
    height: 13rem;
    flex-shrink: 0;
    background: #FFF;
    flex-direction: row;
    justify-content: center;
    align-items: left;
}


.MyPageandSetting {
    width: 22.5rem;
    height: 2.2rem;
    display: flex;
    /* Flexbox 레이아웃 활성화 */
    justify-content: space-between;
    /* 내부 컴포넌트를 양 끝에 배치 */
    margin-top: 2rem;
}

/* My (MY Page) */
.My {
    color: #000;
    font-family: Pretendard-bold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 1.6875rem */
    margin-left: 2rem;
}

/* Setting Button */
.Setting {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    fill: #0A0708;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: transparent;
    background-color: transparent;
    cursor: pointer;
}


.SettingImage {
    width: 1.5625rem;
    height: 1.625rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: 90%;
}

.DefaultProfile {
    width: 5rem;
    height: 5rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: transparent;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 90%;
}

/* Default Profile Image */
.DefaultProfileImage {
    width: 5rem;
    height: 5rem;
}

/* Nickname */
.NickName {
    width: 7.5rem;
    color: #5C2380;
    font-family: Pretendard-Bold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 1.6875rem */
    display: flex;
    justify-items: center;
    align-items: center;
    margin-left: 1.5rem;
}

/* MyPageComponent2 Container */
.MyPageComponent2 {
    margin-bottom: 12rem;
}

.Width100Button2 {
    width: 24.375rem;
    height: 4.25rem;
    flex-shrink: 0;
    background: #FFF;
    color: #000;
    text-align: left;
    font-family: Pretendard-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    border: transparent;
    margin-top: 0.1rem;
    padding-left: 10%;
    cursor: pointer;
    transition: background 0.3s ease;
    /* 배경색 전환을 부드럽게 */
}

.Width100Button2:active {
    background: #DDD;
    /* 클릭 시 어두운 색으로 변경 */
}



.Profile {
    width: 15rem;
    height: 5rem;
    margin-top: 1.5rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: left;
    margin-left: 1.5rem;
}


.Map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(177, 73, 240, 0.05);
    align-items: center;
}

.MapComponent1 {
    width: 24.375rem;
    height: 3.9375rem;
    display: flex;
    flex-shrink: 0;
    background-color: #FFF;
    flex-direction: row;
    align-items: center;

}

.Region {
    width: 10rem;
    height: 1.62rem;
    border: transparent;
    background-color: #FFF;
    background-repeat: no-repeat;
    margin-left: 1.44rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 90%;
}

.PinOff {
    width: 1.3rem;
    height: 1.4rem;
    flex-shrink: 0;
    border: transparent;
    background-color: transparent;
    margin-left: 7.5rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 87%;
}

.MapReturnButton {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    border: transparent;
    background-color: transparent;
    cursor: pointer;
    margin-left: 1rem;
    background-repeat: no-repeat;
    background-size: 100%;
}

.MapComponent2 {
    display: flex;
    width: 24.375rem;
    height: 40.625rem;
    flex-shrink: 0;
    flex-direction: column;
    background-color: transparent;
}

.Hongikcom {
    display: flex;
    width: 11rem;
    height: 5.2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8.5rem;
    margin-left: 12.1rem;
}


.PinOn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.3rem;
    height: 1.4rem;
    flex-shrink: 0;
    border: transparent;
    background-color: transparent;

    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 80%;
}


.Hongik {
    display: flex;
    width: 11rem;
    height: 3.9rem;
    border: transparent;
    background-color: transparent;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 90%;
    margin-left: 1rem;


}

.StarBucks {
    display: flex;
    width: 11rem;
    height: 3.9rem;
    border: transparent;
    background-color: transparent;
    cursor: pointer;
    margin-top: 1.7rem;
    margin-left: 3.4rem;
    background-repeat: no-repeat;
    background-size: 60%;
}

.SangSu {
    display: flex;
    width: 11rem;
    height: 3.9rem;
    background-repeat: no-repeat;
    border: transparent;
    background-color: transparent;
    cursor: pointer;
    margin-top: 10.28rem;
    margin-left: 1.5rem;
    background-repeat: no-repeat;
    background-size: 80%;
}

.ListofLocations {
    width: 24.375rem;
    height: 3.965rem;
    flex-shrink: 0;
    border: transparent;
    background-color: transparent;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 3.6rem;
}


.Back {
    border: transparent;
    background-color: transparent;
    cursor: pointer;
}

.MapContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MapContent1 {
    width: 24.375rem;
    height: 3.9375rem;
    display: flex;
    flex-shrink: 0;
    background-color: #FFF;
    flex-direction: row;
    align-items: center;
}

.Back {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    margin-left: 1.12rem;
}

.MapText {
    color: #000;
    font-family: Pretendard-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 1.5rem */
    margin-left: 1.5rem;
    background-color: transparent;
}

.MapContent2 {
    flex-direction: column;
    width: 24.375rem;
    height: 40.8125rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HongikCom {
    margin-top: 0.44rem;
    width: 20rem;
    height: 11rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border: transparent;
    background-size: 100%;
}

.StarBucksCom {
    width: 20rem;
    height: 11rem;
    flex-shrink: 0;
    background-size: 100%;
    margin-top: 0.94rem;
    align-items: center;
    justify-content: center;
    border: transparent;
}

.SangSuCom {
    width: 20rem;
    height: 11rem;
    flex-shrink: 0;
    background-size: 100%;
    margin-top: 0.94rem;
    margin-bottom: 0.44rem;
    align-items: center;
    justify-content: center;
    border: transparent;
}

.PinOn2 {
    width: 1.3rem;
    height: 1.4rem;
    flex-shrink: 0;
    border: transparent;
    background-color: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 16rem;
    background-size: 80%;
}

.LoginPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.Logo-Big {
    width: 17.6875rem;
    height: 17.66456rem;
    flex-shrink: 0;
    margin-top: 5rem;
    margin-bottom: 5rem;
}


.inputGroup {
    width: 21.4375rem;
    height: 4.625rem;
    flex-shrink: 0;
    margin-bottom: 1.5rem;
}

.inputGroup label {
    color: #000;
    font-family: Pretendard-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 1.25rem */
}

.inputGroup input {
    width: 19rem;
    height: 1rem;
    flex-shrink: 0;
    padding: 1rem;
    background: var(--Gray-scale-Gray-3, #EFEFEF);
    color: var(--7-d-7-d-7-d, #7D7D7D);
    font-family: Pretendard;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    margin-top: 0.3rem;
    border-color: transparent;
    /* 1rem */

}

.LinksAndLogin {
    width: 21.4375rem;
    height: 5rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    justify-content: space-between;
    /* 요소들을 컨테이너의 양 끝으로 정렬 */
}


.click:hover {
    text-decoration: underline;
}

.loginButton {
    width: 9rem;
    height: 3.75rem;
    flex-shrink: 0;
    fill: #EFEFEF;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 5rem;
    border-color: transparent;

}

.loginButton:hover {
    background-color: #00000021;
}

.SignUpPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.title {
    color: #000;
    font-family: Pretendard-Bold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-top: 2rem;
    /* 1.25rem */
}

.signUpButton {
    width: 9rem;
    height: 3.75rem;
    flex-shrink: 0;
    color: #5C2380;
    border-radius: 5rem;
    border-color: transparent;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 3rem;

}

.Information {
    height: 2.5rem;
    width: 21.4375rem;
    background-repeat: no-repeat;
    background-size: 100%;
}

.MakeYourAccount {
    height: 2.5rem;
    width: 21.4375rem;
    background-repeat: no-repeat;
    background-size: 100%;

}

.MapDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(177, 73, 240, 0.05);
}

.MapContent22 {
    width: 24.375rem;
    height: 12.125rem;
    flex-shrink: 0;
}

.HongikImage {
    width: 24.375rem;
    height: 12.125rem;
    flex-shrink: 0;
}

.MapContent33 {
    width: 24.375rem;
    height: 6.0625rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
}

.MapInformationBox {
    display: flex;
    flex-direction: column;
    width: 15rem;
    height: 4rem;
    justify-content: space-between;
    margin-left: 2rem;
}

.MapInformation1 {
    color: #000;
    font-family: Pretendard-Bold;
    font-size: 1rem;
    line-height: 100%;
}

.MapInformation2 {
    color: var(--7-d-7-d-7-d, #7D7D7D);
    font-family: Pretendard;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
}

.PinToggle {
    width: 1.7rem;
    height: 2rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    border: transparent;
    background-color: transparent;
    background-size: 80%;
}

.Add {
    width: 2.25rem;
    height: 2.25rem;
    flex-shrink: 0;
    margin-left: 1rem;
    background-color: transparent;
    background-size: 90%;
    background-repeat: no-repeat;
}

.Posts {
    width: 24.375rem;
    height: 3.375rem;
    flex-shrink: 0;
    margin-top: 0.2rem;
}

.Jelly,
.Rimi {
    width: 23rem;
    height: 16rem;
    flex-shrink: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background-size: 100%;
}

.PostDetailContent1 {
    width: 24.375rem;
    height: 41rem;
    flex-shrink: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.PostDetailIMG {
    width: 24rem;
    height: 45rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    margin-top: 1.88rem;
    size: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    background-repeat: no-repeat;
    background-size: 95%;
}

.AddPosts {
    width: 24.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: 0 auto;
    /* 중앙 정렬을 위한 추가 */
}

.AddPhotos {
    width: 21.4375rem;
    height: 4.8125rem;
    flex-shrink: 0;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.What {
    color: #000;
    font-family: Pretendard-SemiBold;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 1.875rem */
}

.Type {
    display: flex;
    flex-direction: column;
    width: 21.4375rem;
    flex-shrink: 0;
    margin-top: 0.75rem;
}

.Type-1 {
    width: 21.4375rem;
    height: 1.7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.3rem;
    margin-left: 0.4rem;
}

.Check {
    width: 1.2rem;
    height: 1.2rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    margin-right: 1rem;
}

.Post {
    width: 9rem;
    height: 3.75rem;
    flex-shrink: 0;
    fill: #EFEFEF;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 5rem;
    border-color: transparent;
    margin-left: 12rem;
}

.inputGroupBox {
    width: 21.4375rem;
    height: 5rem;
    flex-shrink: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.inputGroupTall {
    height: 8rem;
    /* 원하는 높이로 조정합니다. */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inputGroupTall input {
    flex-grow: 1;
    /* 인풋을 컨테이너의 남은 공간에 채웁니다. */
}

.WhiteBox {
    height: 7rem;
}


.Donation {
    width: 24.375rem;
    height: 52.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.MyPointsSum {
    display: flex;
    width: 24.375rem;
    height: 8rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    background-color: rgba(177, 73, 240, 0.05);
    ;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 3.75rem */
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.ThankstoDonates {
    width: 17.625rem;
    height: 4.875rem;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.Logo {
    width: 10.875rem;
    height: 10.86088rem;
    flex-shrink: 0;
    background-size: 100%;
    margin-top: 2rem;

}

.DonateButton {
    width: 9rem;
    height: 3.75rem;
    flex-shrink: 0;
    fill: #EFEFEF;
    color: #5C2380;
    text-align: center;
    font-family: Pretendard-Bold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 5rem;
    border-color: transparent;

}